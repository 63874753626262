.dropzone {
  width: 100%;
  border: 2px solid #aaa;
  border-style: dashed;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100px;
}

.accepted-file__container {
  display: grid;
  grid-template-areas:
    "svg title"
    "svg fileSize";

  .accepted-file__svg {
    font-size: 40px;
    color: #48c9a7;
    grid-area: svg;
    margin-right: 10px;
  }
  .accepted-file__title {
    grid-area: title;
    font-size: 14px;
    font-weight: 500;
  }
  .accepted-file__size {
    grid-area: fileSize;
    font-size: 14px;
    color: $color-light-text;
  }
}
