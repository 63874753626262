.research__header {
  position: relative;
  background-color: $color-background-blue;
  height: 400px;

  .header__image {
    overflow: hidden;
    width: 100%;

    img {
      display: block;
      margin-left: auto;
      width: 75%;
    }
  }

  .header__content-conteiner {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 1;
    background-color: $color-pink;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
  }

  .header__content {
    width: 100%;
    height: 100%;
    padding: 30px 20px 25px;
    display: flex;
    align-items: center;

    h2 {
      font-size: 32px/36px;
      font-weight: 700;
      font-family: "Deepmind Serif", serif;
      letter-spacing: -0.25px;
      margin: 30px 0;
      color: $color-main-blue;
    }
  }

  .header__meta-container {
    display: flex;
    align-items: center;

    .image {
      width: 40px;
      height: 40px;
      margin: 0 9px 0 0;
      color: $color-white;
      border-radius: 50%;
      background-color: #e1144b;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 20px;
    }
    .page-title {
      font-size: 11px/16px;
      color: $color-subheader;
      font-weight: 700;
      letter-spacing: 2px;
      text-transform: uppercase;
    }
  }
}

.research__body {
  background: linear-gradient(to right, #ffe6f0 50%, #fff 50%);
  overflow: hidden;

  .research__body-content {
    display: flex;
  }

  .research__page-content {
    background-color: #fff;
    padding: 20px 20px 0;
    width: calc(100% - 20px);

    h4 {
      font-weight: 700;
      font-size: 26px;
      line-height: 30px;
      color: $color-main-blue;
      font-family: "Deepmind Serif", serif;
      z-index: 2;
      margin: 50px 0 30px;
    }

    ul {
      margin-bottom: 30px;
    }

    p,
    li {
      font-size: 16px/27px;
      color: $color-subheader;
    }

    p {
      margin: 30px 0;
    }

    img {
      width: 100%;
    }

    li {
      font-size: 16px/27px;
    }

    table {
      width: 100%;
      margin-bottom: 30px;

      td {
        border: 1px solid $color-border-blue;
        text-align: center;
        padding: 8px 8px;
        color: $color-subheader;
        font-size: 16px;
      }
    }
  }

  .research__body-side {
    background-color: $color-pink;
    display: flex;
    flex-direction: column;
    padding: 50px 0 50px 30px;
    width: 100%;

    .authors-list {
      margin-bottom: 30px;
    }

    .authors-container {
      border-top: 1px solid rgba(0, 0, 0, 0.2);
      padding-top: 15px;
      text-align: left;

      ul {
        display: block;
        flex-flow: row wrap;
        list-style: none;
        padding: 0;
      }

      li {
        align-items: center;
        display: flex;
        flex-basis: 50%;
        margin-bottom: 5px;
        color: $color-main-blue;
      }

      .list-initials {
        display: flex;
        align-items: center;
        background-color: $color-main-blue;
        border-radius: 50%;
        height: 50px;
        width: 50px;
        color: $color-white;
        justify-content: center;
        margin-right: 10px;
        min-width: 50px;
      }

      .list-name {
        margin: 0;
        display: flex;
        font-size: 14px;

        span {
          font-size: 10px;
          margin-left: 3px;
        }
      }
    }

    .authors__title {
      font-size: 11px/16px;
      letter-spacing: 2px;
      text-transform: uppercase;
      color: $color-subheader;
      font-weight: 700;
    }

    .footnote {
      font-size: 11px;
    }
  }
}

@media screen and (max-width: 768px) {
  .research__header {
    .header__content {
      padding: 25px 125px 25px 30px;

      h2 {
        font-size: 52px;
        letter-spacing: -0.5px;
        line-height: 60px;
      }
    }
  }

  .research__body {
    .research__page-content {
      padding: 20px 30px 0;
      width: calc(100% - 125px);
    }
    .research__body-side {
      padding: 0;
      .authors-list {
        margin: 0 20px 30px 0;
      }
    }
  }
}

@media screen and (min-width: 1024px) {
  .research__header {
    .header__content {
      padding: 50px 50px 30px 60px;
      // max-width: 485px;

      h2 {
        font-weight: 400;
      }
    }
  }
  .research__body {
    .research__page-content {
      padding: 55px 0 0 30px;
      width: 100%;

      p,
      li {
        font-size: 18px;
        line-height: 28px;
      }
    }
    .research__body-side {
      display: flex;
      max-width: 260px;
      padding: 0 40px 50px 0;
      .authors-list {
        margin: 20px 0 30px;

        li {
          font-size: 18px;
          line-height: 28px;
        }
      }
    }
  }
}

@media screen and (min-width: 1440px) {
  .research__header {
    .header__content {
      // max-width: 745px;
      padding: 50px 50px 30px 90px;

      h2 {
        letter-spacing: -1px;
        font-size: 55px;
        line-height: 65px;
        font-weight: 700;
      }
    }
  }
  .research__body {
    .research__page-content {
      padding: 70px 0 0 100px;

      h4 {
        font-size: 30px;
        letter-spacing: -0.25px;
        line-height: 34px;
      }

      p,
      li {
        font-size: 18px;
        line-height: 28px;
      }
    }
  }
  .research__body-side {
    max-width: 300px;
  }
}
