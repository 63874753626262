.protein__legend {
  display: flex;
  flex-direction: column;
  list-style: none;
  padding-left: 0;
  margin-left: -30px;
  // border: 1px solid red;
  max-height: 300px;
  overflow-y: scroll;

  &.lg {
    margin-left: -100px;
  }

  &.reversed {
    float: right;
    margin-right: -100px;
  }

  &.prediction {
    margin-right: -150px !important;
  }

  .legend__box {
    width: 12px;
    height: 12px;
    display: block;
    margin-right: 5px;
  }

  li {
    display: flex;
    align-items: center;
    margin-right: 10px;
    font-size: 1rem;
    font-weight: 400;

    &.lg {
      font-size: 12px;
    }

    .legend__box-content {
      max-width: 100px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  &::-webkit-scrollbar {
    height: 0px;
    width: 10px;
    opacity: 0.3;
    background-color: $color-white;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    width: 10px;
    border: 2.5px solid $color-white;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $color-main-blue;
  }
}

.protein__tooltip {
  background-color: rgba($color: #ffffff, $alpha: 0.7);
  border: 1px solid $color-light-text;
  padding: 10px;
  max-width: 320px;
  white-space: normal;

  .protein-name {
    color: $color-light-text;
    margin-bottom: 5px;
  }

  .mutations {
    margin-bottom: 5px;
    font-size: 18px;
    font-weight: 500;

    .mutations__item {
      margin-right: 10px;
    }
  }

  .occurrences {
    font-size: 24px;
    margin-bottom: 0;
  }
}
