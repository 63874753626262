@import url("https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&family=DM+Serif+Display:ital@0;1&display=swap");
@import url("https://fonts.googleapis.com/css2?family=DM+Serif+Text&display=swap");

main {
  padding: 0;
}

body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "DM Sans", sans-serif;
  font-size: 13px;
  line-height: 1.6;
}

* {
  box-sizing: inherit;
}

a {
  color: $color-blue;
  transition: all 0.3s;

  &:hover {
    text-decoration: none;
    // color: $color-blue-hover;
  }
}

img {
  width: 100%;
}

.container__wrap {
  width: calc(100% - 100px);
  float: right;
}

.section {
  // padding-left: 100px;
  max-width: 1440px;
  padding: 0 20px;
  margin: 0 auto;
  width: 100%;
}

// @media screen and (min-width: 768px) {
//   .section {
//     padding: 0 30px;
//   }
// }
// @media screen and (min-width: 1024px) {
//   .section {
//     padding: 0 60px;
//   }
// }
@media screen and (min-width: 1440px) {
  .section {
    padding: 0 90px;
  }
}
