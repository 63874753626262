.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba($color: #000000, $alpha: 0.5);
  z-index: 1055;
  cursor: pointer;
  overflow-x: hidden;
  overflow-y: auto;
  // display: block;

  .modal__container {
    background: $color-white;
    width: 100%;
    max-width: 500px;
    padding: 30px;
    border-radius: 5px;
    cursor: default;
    margin: 1.75rem auto;

    &.lg {
      max-width: 800px;
    }

    &.xl {
      max-width: 1140px;
    }
  }
}
