.contacts__container {
  display: flex;
  align-items: center;

  .contacts__left {
    flex: 1;
    color: $color-white;
    margin-left: 50px;

    h3,
    h5,
    p {
      margin-bottom: 40px;
    }

    h3 {
      text-transform: uppercase;
    }

    h5,
    p {
      width: 450px;
    }
  }

  .contacts__right {
    flex: 1;

    .contacts__card {
      // margin-left: auto;
      width: 350px;
      background-color: $color-white;
      box-shadow: 0px 0px 15px 0px rgba(0, 33, 84, 0.75);
      -webkit-box-shadow: 0px 0px 15px 0px rgba(0, 33, 84, 0.75);
      -moz-box-shadow: 0px 0px 15px 0px rgba(0, 33, 84, 0.75);
      padding: 20px 50px;

      h5,
      p,
      a {
        text-align: center;
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .contacts__container {
    flex-direction: column;
    width: 100%;
    margin-left: -40px;
    margin-top: 5%;

    .contacts__left {
      margin-left: 0;

      h3,
      h5 {
        text-align: center;
      }
    }

    .contacts__right {
      .contacts__card {
        width: 500px;
      }
    }
  }
}
