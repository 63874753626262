.form {
  display: flex;
  flex-direction: column;
  position: relative;
  flex-wrap: wrap;
  text-align: left;
  direction: ltr;
  width: 100%;

  input,
  textarea,
  .form__form-file-field,
  .autocomplete__form-wrap {
    width: 100%;
    max-width: 100%;
    padding: 5px 10px;
    font-size: 16px;
    height: 42px;
    transform-origin: border 0.3s;
    background-color: $color-background;

    &::-webkit-input-placeholder {
      color: $color-light-text;
    }
    &::-moz-placeholder {
      color: $color-light-text;
    }
    /* Firefox 19+ */
    &:-moz-placeholder {
      color: $color-light-text;
    }
    /* Firefox 18- */
    &:-ms-input-placeholder {
      color: $color-light-text;
    }

    border: none;
    border-bottom: 2px solid $color-dusty-white;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    color: $color-dark-text;

    &:focus,
    &:active {
      outline: none;
      border-color: $color-main-blue;
    }
  }
}

.form__form-group-input-wrap {
  width: 100%;
}

textarea {
  min-height: 85px;
}

.form__form-group {
  margin-bottom: 20px;
  width: 100%;
  position: relative;

  .form__switch-group {
    .form__switch-group-label {
      font-size: 14px;
      margin-right: 10px;
    }
  }
}

.form__form-group-field {
  width: 100%;
  display: flex;
  margin: auto;
  direction: ltr;
}

.form__form-group-label {
  margin-bottom: 4px;
  display: inline-block;
  color: $color-text;
  font-size: 14px;
  position: relative;

  span {
    // color: $color-red;
    cursor: pointer;
    text-decoration: underline;
  }
}

.form__form-group-error {
  font-size: 10px;
  line-height: 13px;
  color: $color-dark-red;
  margin-bottom: -5px;
  display: block;
  margin-top: 10px;
}

.form__form-group-input-wrap--error-above {
  .form__form-group-error {
    position: absolute;
    margin: 0;
    right: 0px;
    top: 0;
    padding: 5px 10px;
    background: $color-red;
    border-radius: 3px;

    &:after {
      content: "";
      position: absolute;
      right: 10px;
      bottom: -8px;
      border: 4px solid transparent;
      border-top: 4px solid $color-red;
    }
  }
}

.autocomplete__menu {
  padding-left: 0;
  list-style: none;
  border: 1px solid $color-border-blue;
  max-height: 200px;
  overflow-y: scroll;

  li {
    padding: 5px;
    &:hover {
      background-color: lighten($color-border-blue, 15%);
    }
  }
}

.form {
  .autocomplete__form-wrap {
    height: auto;
    min-height: 42px;
    background-color: $color-background;
    display: inline-flex;
    flex-wrap: wrap;
    pointer-events: none;
    padding: 0;
    align-content: center;

    input {
      flex-grow: 1;
      width: auto;
      pointer-events: auto;
      height: auto;
      border: none;
      padding: 0;
      padding-left: 10px;
    }

    &:focus-within,
    &:active,
    &:hover {
      outline: none;
      border-color: $color-main-blue;
    }

    .autocomplete__tag {
      margin: 2px;
      max-width: calc(100% - 4px);
      height: 24px;
      background-color: $color-dusty-white;
      border-radius: 20px;
      font-size: 14px;
      display: flex;
      padding-left: 8px;
      padding-right: 8px;
      align-items: center;
    }

    .tag-item {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      padding-right: 5px;
      pointer-events: auto;
      cursor: default;
    }

    .tag-delete {
      background-color: $color-gray;
      color: $color-dusty-white;
      width: 15px;
      height: 15px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      pointer-events: auto;
    }
  }
}
