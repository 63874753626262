.sidebar {
  height: 100%;
  width: 100px;
  background-color: $color-main-blue;
  display: block;
  position: fixed;
  z-index: 35;

  &::after {
    background-color: #0053d6;
    content: "";
    height: 100%;
    position: absolute;
    right: 0px;
    top: 0;
    transition: right 0.3s ease, width 0.3s ease;
    width: 0;
    z-index: 34;
  }

  .sidebar__container {
    padding: 30px 25px;
    height: 100%;
    display: flex;
    flex-direction: column;

    .sidebar__header {
      display: flex;
      flex-direction: column;

      .sidebar__logo {
        .image {
          width: 50px;
          height: 60px;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;

          img {
            width: auto;
            height: 100%;
          }
        }
      }
      .sidebar__menu-btn {
        position: relative;
        height: 18px;
        width: 30px;
        display: flex;
        cursor: pointer;
        flex-direction: column;
        align-items: center;
        .menu-btn-line {
          background-color: $color-white;
          height: 2px;
          width: 100%;
          margin: 0;
          position: absolute;
          display: block;
          transition: transform 0.3s ease, width 0.3s ease;
          -webkit-transform: 0.3s ease;

          &:nth-child(1) {
            top: 0;
          }
          &:nth-child(2) {
            top: 8px;
          }
          &:nth-child(3) {
            bottom: 0;
          }
        }
      }
      .sidebar__menu-btn:hover {
        .menu-btn-line:nth-child(2) {
          width: 50%;
        }
      }
    }

    .sidebar__content {
      margin-top: 30px;
      display: block;

      .sidebar__navigation {
        .sidebar__pages {
          display: flex;
          flex-direction: column;
          list-style: none;
          padding: 0;

          a {
            color: $color-white;
            text-decoration: none;
          }
          .sidebar__navigation-item {
            font-size: 14px;
            line-height: 28px;
            padding: 10px 0;
            border-top: 1px solid;
            border-top-color: rgba($color: #ffffff, $alpha: 0.2);
            color: $color-white;
            cursor: pointer;
            position: relative;
            & a {
              color: $color-white;
              text-decoration: none;
            }
            &::before {
              border-left: 6px solid transparent;
              content: "";
              display: block;
              height: 100%;
              left: -20px;
              position: absolute;
              top: 0;
            }
          }
          .sidebar__navigation-item:hover {
            &::before {
              border-left-color: white;
            }
          }
        }
      }
    }

    .sidebar__socials {
      display: flex;
      flex-direction: column;
      margin-top: auto;

      .socials-item {
        font-size: 20px;
        padding: 3px 0;

        a {
          color: $color-white;
        }
      }
    }
  }

  &.collapsed {
    .sidebar__container {
      .sidebar__content {
        display: none;
      }
    }
    .sidebar__header {
      .sidebar__menu-btn {
        .menu-btn-line:nth-child(1) {
          transform: translateY(8px) rotate(45deg);
        }
        .menu-btn-line:nth-child(2) {
          width: 0 !important;
        }
        .menu-btn-line:nth-child(3) {
          transform: translateY(-8px) rotate(-45deg);
        }
      }
    }
  }

  &.hovered {
    &::after {
      right: -10px;
      width: 10px;
    }
  }
}
