.page__container {
  padding: 30px 20px;
  background-color: $color-background;
}

.footer {
  // height: 100px;
  background-color: $color-subheader;
  padding: 20px 50px 10px;
  display: flex;
  align-items: center;

  .cite-request {
    flex: 1;
    text-align: center;
    p {
      margin-bottom: 0.5rem;
      color: $color-background;

      span {
        font-style: italic;
      }
    }
    a {
      text-decoration: none;
      color: $color-white;
      font-weight: 700;
    }
  }

  .logo__container {
    height: 50px;
    margin: 0 10px;
    cursor: pointer;

    img {
      width: auto;
      height: 100%;
    }
  }
}
