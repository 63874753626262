.results-page__item {
  display: flex;
  margin-bottom: 50px;

  &.reversed {
    flex-direction: row-reverse;
  }

  .graph__container {
    flex: 1;
    // height: 400px;
    margin-right: 30px;
  }

  .description__container {
    flex: 1;
  }
}

.graphs__container {
  display: flex;
  justify-content: space-between;

  .graph__item-container {
    max-width: 50%;

    .graph__item-title {
      margin-left: 50px;
      margin-bottom: 20px;
      font-size: 14px;
      font-weight: 500;
    }

    .graph__item-description {
      padding: 20px 4em;
    }
  }
}
