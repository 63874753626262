.error-message__container {
  display: flex;
  flex-direction: column;
  justify-items: center;
  align-items: center;

  .error-message__icon {
    font-size: 42px;
    color: $color-red;
  }

  .error-message__text {
    font-size: 14px;
    color: $color-red;
    font-weight: 600;
    text-align: center;
  }
}
