.page {
  position: relative;
  .header {
    width: 100%;
    // box-shadow: inset 0 0 0 2000px rgba(0, 0, 0, 0.3);
    background-size: cover !important;

    &.main {
      background: url("../../assets/img/headerBg.png") center no-repeat;
    }

    &.about {
      background: url("../../assets/img/aboutBg.jpg") center no-repeat;
    }

    .header__bg {
      position: absolute;
      display: block;
      height: 100%;
      left: 0;
      overflow: hidden;
      pointer-events: none;
      top: 0;
      width: 100%;
    }

    .header__title {
      position: absolute;
      border-bottom: 2px solid $color-main-blue;
      top: 30px;
      left: 30px;
      color: $color-main-blue;
      font-size: 40px;
      letter-spacing: -1px;
      line-height: 45px;
      padding: 0 50px;
    }

    .header__container {
      display: flex;
      padding: 0 80px;
      max-width: 50%;
      justify-content: center;
      flex-direction: column;
      height: 100%;
      min-height: 500px;
    }
  }
}

.header__content {
  max-width: 275px;

  h1 {
    color: $color-main-blue;
    font-size: 40px;
    letter-spacing: -1px;
    font-weight: 200;
    line-height: 50px;
    font-family: "DM Serif Text", serif;
  }
}

.main-row {
  display: flex;
}

.page__intro {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px auto;
  max-width: 1000px;
  text-align: center;

  ul {
    margin-bottom: 20px;
  }

  p,
  li {
    font-size: 18px;
    color: $color-subheader;
  }

  p {
    text-align: left;
    margin: 30px 0;
  }

  li {
    text-align: left;
  }

  a {
    color: $color-main-blue;
    text-decoration: none;
  }

  h4 {
    margin: 30px 0;
    width: 100%;
    font-size: 30px;
    letter-spacing: -0.25px;
    line-height: 34px;
    font-family: "DM Serif Text", serif;
    font-weight: 200;
    color: $color-main-blue;
  }

  hr {
    background-color: $color-main-blue;
    border: 0;
    height: 1px;
    width: 100px;
  }
}

@media screen and (max-width: 1024px) {
  .header__content {
    max-width: 100%;
  }

  .page {
    .header {
      .header__container {
        max-width: 100%;
      }
    }
  }
}

@media screen and (min-width: 1440px) {
  .header__content {
    max-width: 790px;
  }
}
