.nav {
  padding-left: 10px;
  padding-bottom: 23px;
}
.tabs {
  overflow: auto;

  .tabs__wrap {
    text-align: left;
    min-width: min-content;
  }

  .nav-tabs {
    li {
      .nav-link {
        padding: 10px 20px 5px;
        margin-right: 0px;
        font-weight: 700;
        transition: background-color 0.3s;
        cursor: pointer;
        color: $color-text;

        &:hover,
        &:focus,
        &:active {
          outline: none;
          background-color: lighten($color-border-blue, 10%);
          border-color: lighten($color-border-blue, 10%);
          border-bottom: 2px solid $color-subheader;
          color: $color-subheader;
          border-radius: 2px;
        }
      }

      .nav-link.active,
      .nav-link.active:focus,
      .nav-link.active:hover {
        background-color: lighten($color-border-blue, 15%);
        border-color: lighten($color-border-blue, 15%);
        border-bottom: 2px solid $color-subheader;
        border-radius: 2px;
        color: $color-subheader;
      }

      &.disabled .nav-link {
        color: $color-text;
      }
    }
  }

  .tab-pane {
    padding-top: 20px;
  }

  &.tabs--bordered-top {
    .nav-tabs {
      li .nav-link {
        border-top: 2px solid transparent;
        border-radius: 0;
      }

      li .nav-link.active {
        &,
        &:focus,
        &:hover {
          border-radius: 0;
          border-top: 2px solid $color-border-blue;
        }
      }
    }
  }

  &.tabs--bordered-bottom {
    .nav-tabs {
      li .nav-link {
        border-color: transparent;
        border-bottom: 1px solid transparent;
        padding-bottom: 11px;
      }

      li .nav-link.active {
        &,
        &:focus,
        &:hover {
          border-radius: 0;
          border-color: transparent;
          border-bottom: 2px solid $color-border-blue;
          padding-bottom: 10px;
        }
      }
    }
  }

  &.tabs--justify {
    .nav-tabs {
      display: flex;
      flex-wrap: nowrap;

      li {
        width: 100%;

        a {
          text-align: center;
        }
      }
    }
  }

  &.tabs--vertical {
    overflow-y: auto;

    .tabs__wrap {
      display: flex;
    }

    .nav-tabs {
      border-bottom: none;
      width: 90px;
      height: 100%;
      flex-wrap: wrap;

      .nav-item {
        margin-bottom: 0;
      }

      li {
        margin-top: 0;
        width: 100%;

        .nav-link {
          padding-right: 10px;
          padding-left: 0px;
          margin-right: 0px;
          border-right: 2px solid #ddd;
          padding-top: 10px;
          padding-bottom: 10px;
          transition: background-color 0.3s;
          border-radius: 0;
          border: none;
          color: $color-text;

          &:hover,
          &:focus,
          &:active {
            outline: none;
            background-color: $color-border-blue;
          }
        }

        .nav-link.active,
        .nav-link.active:focus,
        .nav-link.active:hover {
          background-color: transparent;
          border-right-color: $color-border-blue;
        }

        &.disabled .nav-link {
          color: $color-text;
        }
      }
    }

    .tab-pane {
      padding-top: 0;
      padding-left: 20px;
    }

    &.tabs--vertical-colored {
      .nav-tabs {
        li {
          .nav-link {
            border-right-color: #eee;
            padding-left: 10px;
            text-align: center;
          }

          .nav-link.active,
          .nav-link.active:focus,
          .nav-link.active:hover {
            background-color: #eeeeee;
            border-right-color: #eee;
            color: $color-text;
          }
        }
      }
    }
  }
}

.tab__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  // margin-bottom: 30px;

  .tab__header--left {
    display: flex;
  }

  .tab__header--title,
  .tab__header--view {
    font-size: 15px;
    letter-spacing: 0.1px;
  }

  .tab__header--title {
    text-transform: uppercase;
    color: $color-dark-text;
    margin-right: 20px;
  }

  .tab__header--view {
    color: $color-main-blue;
    display: flex;
    align-items: center;
    cursor: pointer;

    &:hover {
      color: lighten($color: $color-main-blue, $amount: 10);
    }

    .tab__header--view-icon {
      color: $color-gray;
      margin-left: 20px;
      font-size: 24px;
    }
  }
}

.contingency-box__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 30px;

  .contingency-box__box {
    text-align: center;
    font-size: 15px;
    padding: 10px 15px;
    margin-bottom: 12px;

    &.primary {
      background-color: $color-main-blue;
      color: $color-white;
    }

    &.accent {
      background-color: $color-border-blue;
      color: $color-subheader;
    }
  }

  .contingency-box__text {
    font-weight: 600;
    font-size: 15px;
    letter-spacing: 0.1px;

    &.primary {
      color: $color-main-blue;
    }

    &.accent {
      color: $color-subheader;
    }
  }
}

.tab__graph-container {
  min-height: 400px;
  margin-left: -20px;
  margin-top: 20px;
}

.over-time__subheader {
  display: flex;
  align-items: center;
  margin-top: 30px;
  justify-content: space-between;

  .over-time__range {
    display: flex;
    color: $color-main-blue;
    align-items: center;
    cursor: pointer;

    p {
      color: $color-main-blue;
    }
  }

  .over-time__zoom {
    display: flex;
    align-items: center;

    span {
      font-size: 14px;
      color: $color-subheader;
      font-weight: 600;
    }

    ul {
      display: flex;

      li {
        list-style: none;
        margin: 0 10px;
        padding: 3px 15px;
        background-color: $color-border-blue;
        font-size: 13px;
        color: $color-subheader;
        font-weight: 600;
        cursor: pointer;

        &:hover,
        &.active {
          background-color: $color-subheader;
          color: $color-white;
        }
      }
    }
  }
}

.overflow {
  width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
}
