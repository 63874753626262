.menuModal {
  width: 100vw;
  height: 100vh;
  position: fixed;
  background-color: $color-main-blue;
  z-index: 30;
  left: -100vw;
  transition: left 0.5s ease;
  display: flex;
  justify-content: space-between;

  .modal__close {
    position: absolute;
    top: 0;
    right: 100px;
    margin: 40px 0;
    text-align: right;
    color: $color-white;
    font-size: 40px;
    letter-spacing: -1px;
    cursor: pointer;
  }

  .modal__title {
    position: absolute;
    top: 0;
    left: calc(50% - 40px);
    text-align: center;
    margin: 50px auto;
    color: $color-white;
    font-size: 30px;
    letter-spacing: -1px;
  }

  .modal__wrapper {
    // max-width: 1200px;
    padding: 140px 80px 35px;
    margin-left: 100px;
    height: 100%;
    width: 100%;

    .modal__content {
      display: flex;
      .modal__menu-item {
        margin: 0;
        width: calc(1 / 6 * 100% - 40px);
        margin-left: 40px;
        margin-bottom: 30px;
        opacity: 0;
        transform: translateX(-100px);
        transition: opacity 0.3s ease, transform 0.3s ease;

        &:nth-child(1),
        &:nth-child(7) {
          margin: 0;
        }

        .menu-item__header {
          font-size: 16px;
          color: $color-white;
          font-weight: 700;
          margin: 10px 0;
        }
        .menu-item__options {
          padding: 0 0 0 8px;
          border-left: 1px solid $color-border-blue;
          list-style: none;

          .menu-item__option {
            margin: 10px 0;
            font-size: 14px/18px;
            // margin-top: 10px;
            color: $color-white;
          }
        }
        &.show {
          opacity: 1;
          transform: translateX(100px);
        }
      }
    }
  }

  &.collapsed {
    left: 0;
    overflow: hidden;
  }
}

@media screen and (max-width: 1024px) {
  .menuModal {
    .modal__title {
      left: calc(50% - 70px);
    }

    .modal__wrapper {
      padding: 120px 0 35px;
    }
  }
}
