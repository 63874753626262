.postCard {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  cursor: pointer;
  min-height: 525px;
  min-width: 280px;
  width: 50%;
  margin: 0 0 40px;
  background-color: $color-pink;
  transition: 0.3s;

  &:hover {
    transform: translateY(-15px);
  }

  &:nth-child(2) {
    margin: 0 0 40px 40px;
  }

  .postCard__image {
    min-height: 200px;
    position: relative;
    width: 100%;
    max-height: 400px;
    overflow: hidden;

    img {
      max-width: auto;
      height: 400px;
    }
  }

  .postCard__content {
    padding: 25px;
    display: flex;
    flex-direction: column;

    .postCard__title {
      font-family: "DM Serif Text", serif;
      font-weight: 200;
      color: $color-main-blue;
      text-decoration: none;
      margin-bottom: 10px;
      font-size: 30px;
      letter-spacing: -0.25px;
      line-height: 34px;
    }

    .postCard__text {
      color: $color-subheader;
      font-size: 16px;
      margin-bottom: 10px;
    }

    .postCard__footer {
      display: flex;
      align-items: center;
      margin: auto 0 0;
      padding-top: 15px;

      .postCard__button {
        margin-left: auto;
        background-color: $color-yellow;
        border-radius: 50%;
        color: $color-main-blue;
        height: 30px;
        width: 30px;
        display: flex;
        align-items: center;
        font-size: 25px;
        transition: 0.2s;
        cursor: pointer;

        .postCard__button-icon {
          transform: translateX(-3px);
        }

        &:hover {
          background-color: $color-main-blue;
          color: $color-white;
        }
      }
    }
  }

  .card__modal {
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0;
    pointer-events: none;

    &.collapsed {
      width: 100vw;
      height: 100vh;
      background-color: white;
      opacity: 1;
      top: 0;
      left: 0;
    }
  }
}

@media screen and (max-width: 1024px) {
  .postCard {
    .postCard__image {
      max-height: 300px;
    }
  }
}
