.modal-form__container {
  width: 100%;

  .modal-form__subpage-title {
    text-align: center;
    margin-bottom: 20px;
    font-size: 16px;
    font-weight: 500;
  }
  .modal-form__subpage {
    width: 100%;
    .modal-form__subpage__or {
      text-align: center;
      font-weight: 500;
      position: relative;

      &::after,
      &::before {
        content: "";
        position: absolute;
        background-color: $color-black;
        top: 50%;
        transform: translateY(-50%);
        height: 1px;
        width: 10px;
      }
      &::after {
        margin-left: 2px;
      }

      &::before {
        margin-left: -12px;
      }
    }
  }

  .dropzone__files {
    margin-top: 10px;
  }
}

.analysis-option__container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 10px 20px 30px;
  border: 2px solid rgba($color: #000000, $alpha: 0.3);
  margin-bottom: 20px;
  cursor: pointer;

  &.active {
    border-color: $color-main-blue;
  }

  .analysis-option__icon-container {
    width: 30px;
    height: 30px;

    .analysis-option__icon {
      width: 100%;
      height: auto;
    }
  }

  .analysis-option__info {
    max-width: 85%;

    .analysis-option__header {
      font-size: 14px;
      font-weight: 600;
    }

    .analysis-option__description {
      margin-bottom: 0;
    }
  }
}

.genome-check__container {
  width: 100%;
}
