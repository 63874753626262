.spinner-container {
  background-color: #fff;

  z-index: 99;
  //   position: fixed;
  width: 200px;
  padding: 10px;
  //   top: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  //   left: 50%;
  //   margin-top: -100px;
  //   margin-left: -50px;
  margin: 0 auto;
  border-radius: 0.5rem;
}

.spinner-container p {
  text-align: center;
  margin: 5px 0 0 0;
  font-size: 14px;
  color: #fd0098;
  font-weight: 500;
}

#upperbar {
  animation: upperBar linear;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  transform-origin: center;
  transform-box: border-box;
}

#middlebar {
  animation: middlebar ease;

  animation-duration: 2s;
  animation-iteration-count: infinite;
  transform-origin: center;
  transform-box: border-box;
}

#bottombar {
  animation: bottombar ease;

  animation-duration: 2s;
  animation-iteration-count: infinite;
  transform-origin: center;
  transform-box: border-box;
}

@keyframes upperBar {
  0% {
    transform: translateX(0px);
  }
  50% {
    transform: translateX(15px);
  }
  100% {
    transform: translateX(0px);
  }
}

@keyframes middlebar {
  0% {
    transform: translateX(-20px);
  }
  50% {
    transform: translateX(0px);
  }
  100% {
    transform: translateX(-20px);
  }
}

@keyframes bottombar {
  0% {
    transform: translateX(0px);
  }
  50% {
    transform: translateX(10px);
  }
  100% {
    transform: translateX(0px);
  }
}
