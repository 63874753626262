$color-white: #fff;
$color-black: #000;
$color-blue: #70bbfd;
$color-gray: #787985;
$color-light-gray: #c9cad3;
$color-blue-hover: darken($color-blue, 10%);

$color-main-blue: #0053d6;
$color-border-blue: #79a2fb;
$color-background-blue: #f0f5f5;

$color-green: #b8e986;
$color-green-hover: darken($color-green, 10%);

$color-yellow: #ffdb13;
$color-pink: #ffe6f0;

$color-subheader: #132148;

$color-text: #555;
$color-light-text: #777;
$color-dark-text: #222;

$color-dusty-white: #dddddd;
$color-background: #f7f9fa;

$color-red: #c01f28;
$color-dark-red: #84151b;

$color-success: #039789;
