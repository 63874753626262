.notification {
  max-width: 400px;
  width: calc(100% - 50px);
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.07);
  background: $color-white;
  position: relative;
  margin: 10px 25px;
  z-index: 1100;

  &.notification--full-wide {
    max-width: 100vw;
    width: 100vw;
    margin: 0;
    padding: 10px 40px 10px 25px;

    .notification__message {
      text-align: center;
      //   width: calc(100% - 30px);
      padding-left: 100px;
      margin: 0;
    }
  }

  &.notification--primary {
    background: $color-main-blue;

    .notification__message,
    .notification__title {
      color: $color-white;
    }
  }

  &.notification--success {
    background: $color-green;

    .notification__message,
    .notification__title {
      color: $color-white;
    }
  }

  &.notification--warning {
    background: $color-yellow;

    .notification__message,
    .notification__title {
      color: $color-white;
    }
  }

  &.notification--danger {
    background: $color-red;

    .notification__message,
    .notification__title {
      color: $color-white;
    }
  }
}
