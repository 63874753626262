.btn__toolbar {
  display: flex;
  width: 100%;
  margin-top: 20px;
}

.btn__yellow {
  padding: 12px 18px;
  border-radius: 30px;
  line-height: 1.6;
  background-color: $color-yellow;
  color: $color-main-blue;
  font-size: 11px;
  letter-spacing: 2px;
  text-transform: uppercase;
  transition: 0.25s ease;
  cursor: pointer;
  border: 0;

  &:hover {
    background-color: $color-main-blue;
    color: $color-white;
  }
}

.btn__yellow:disabled,
.btn__yellow[disabled] {
  background-color: $color-light-text;
  color: $color-white;
  cursor: default;
}

.btn__with_margin {
    margin: 12px 6px 12px 6px;
}

.radio-button__outer {
  width: 20px;
  height: 20px;
  border: 3px solid $color-light-text;
  border-radius: 50%;
  cursor: pointer;
  position: relative;

  &:hover,
  &:focus {
    &::after {
      position: absolute;
      content: "";
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 40px;
      height: 40px;
      background-color: rgba($color: #000000, $alpha: 0.1);
      border-radius: 50%;
    }
  }

  &.active {
    border-color: $color-main-blue;
    .radio-button__inner {
      width: 10px;
      height: 10px;
      background-color: $color-main-blue;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      border-radius: 50%;
    }

    &:hover,
    &:focus {
      &::after {
        position: absolute;
        content: "";
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 40px;
        height: 40px;
        background-color: rgba($color: $color-main-blue, $alpha: 0.1);
        border-radius: 50%;
      }
    }
  }
}
