.wizard-navigation {
  counter-reset: num;
  margin-bottom: 50px;
  padding: 0;
  display: flex;
  width: 100%;
}

.wizard-navigation__item {
  margin: 0;
  padding: 0;
  display: flex;
  width: 100%;
}

.wizard-navigation__item {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;

  .wizard-navigation__item-title {
    margin-top: 5px;
    color: $color-light-text;
    text-transform: uppercase;
    font-size: 11px;
    font-weight: 500;
  }

  &::before {
    counter-increment: num;
    content: counter(num);
    width: 3em;
    height: 3em;
    text-align: center;
    line-height: 3em;
    border-radius: 50%;
    font-weight: 500;
    color: $color-light-text;
    background: #ddd;
    border: 2px solid $color-light-text;
    z-index: 1;
  }

  & ~ ::after {
    content: "";
    position: absolute;
    width: 100%;
    right: 50%;
    height: 1px;
    background-color: $color-light-text;
    top: calc(1.5em - 2px);
    z-index: 0;
  }

  &.active {
    .wizard-navigation__item-title {
      color: $color-main-blue;
    }

    &::before {
      border-color: $color-main-blue;
      color: $color-main-blue;
      background-color: $color-white;
    }
    &::after {
      background-color: $color-main-blue;
    }
  }

  &.completed {
    .wizard-navigation__item-title {
      // color: $color-black;
      color: $color-light-text;
    }

    &::before {
      // border-color: $color-main-blue;
      // background-color: $color-main-blue;
      // color: $color-white;
      border-color: $color-light-text;
      background-color: #ddd;
      color: $color-light-text;
    }
    &::after {
      background-color: $color-light-text;
    }
  }
}
